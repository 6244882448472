* {
	margin: 0;
	padding: 0;
}

/* .image-bg {
	background:
		url("https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVhbCUyMGVzdGF0ZXxlbnwwfHwwfHw%3D&w=1000&q=80");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
} */
body {
	overflow: auto;
	background-color: #efefef;
}

html {
	max-width: 100%;
	scroll-behavior: smooth;
	font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
		Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
		Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.navbar img {
	width: 70px;
	height: 65px;
	margin-right: 10px;
	margin-left: 0;
	/* 	margin-top: 10px;
 */
}

.navbar-text {
	font-size: 30px;
	font-weight: bold;
	color: #287DFD !important;
}

.navbar-subtext {
	font-size: 14px;
	color: #888;
	margin-top: -22px;
	margin-left: 87px;
}

@media only screen and (min-width: 200px) and (max-width: 375px) {
	.navbar-subtext {
		color: #888;
		margin-top: -21px;
		margin-left: 90px;
		font-size: 8px;
	}
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
	.navbar-subtext {
		font-size: 11px;
		color: #888;
		margin-top: -21px;
		margin-left: 89px;
	}
}

.navbar-light .navbar-nav .nav-link {
	color: black !important;
	margin-right: 10px;
	font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
	border-bottom: 2px solid #287DFD;
}

@media only screen and (min-width: 1439px) {
	.navbar-light .navbar-nav .nav-link {
		color: black !important;
		margin-right: 10px;
		font-size: 20px !important;
	}
}

.button-look {
	border: 2px solid #287DFD;
	border-radius: 5px;
	align-items: center;
	text-align: center;
	padding-left: 10px !important;
}

.button-look a:hover {
	color: white;
}
.button-look:hover {
	background: #287DFD;
	color: white !important;
}

/*form styles*/
#msform {
	position: relative;
	margin-top: 30px;
	text-align: center;
	margin-bottom: 30px;
}

#msform p {
	position: relative;
	margin-top: 20px;
	text-align: left;
	margin-bottom: 20px;
	color: #070707 !important;
}

#msform fieldset {
	background: white;
	border: 0 none;
	border-radius: 8px;
	padding: 20px 30px;
	box-sizing: border-box;
	width: 40%;
	margin: 0 auto;
	/*stacking fieldsets above each other*/
	position: relative;
}

@media only screen and (min-width: 200px) and (max-width: 424px) {
	#msform fieldset {
		background: white;
		border: 0 none;
		border-radius: 8px;
		padding: 20px 30px;
		box-sizing: border-box;
		width: 90% !important;
		margin: 0 auto;
		/*stacking fieldsets above each other*/
		position: relative;
	}
	.checkbox-pair {
		flex-basis: 70% !important;
		margin-bottom: 10px;
	}
}

@media only screen and (min-width: 424px) and (max-width : 1023px) {
	#msform fieldset {
		background: white;
		border: 0 none;
		border-radius: 8px;
		padding: 20px 30px;
		box-sizing: border-box;
		width: 90% !important;
		margin: 0 auto;
		/*stacking fieldsets above each other*/
		position: relative;
	}
	.checkbox-pair {
		flex-basis: 48% !important;
		margin-bottom: 10px;
	}
}
/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
	display: none;
}

/*inputs*/
#msform input[type=text], #msform textarea, #msform input[type=email],
	#msform input[type=number] {
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 100%;
	box-sizing: border-box;
	color: #2C3E50;
	font-size: 13px;
}

#msform input[type=date] {
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
	margin-top: 10px;
	box-sizing: border-box;
	color: #2C3E50;
	font-size: 13px;
}

#msform input:focus, #msform textarea:focus {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: 1px solid #2098ce;
	outline-width: 0;
	transition: All 0.5s ease-in;
	-webkit-transition: All 0.5s ease-in;
	-moz-transition: All 0.5s ease-in;
	-o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
	width: 100px;
	background: #3f8efc;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 25px;
	cursor: pointer;
	padding: 10px 5px;
	margin: 10px 5px;
}

#msform .action-button:hover, #msform .action-button:focus {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #2098ce;
}

#msform .action-button-previous {
	width: 100px;
	background: #aCbEd0;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 25px;
	cursor: pointer;
	padding: 10px 5px;
	margin: 10px 5px;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus
	{
	box-shadow: 0 0 0 2px white, 0 0 0 3px #aCbEd0;
}

/*headings*/
.fs-title {
	font-size: 18px;
	text-transform: uppercase;
	color: #2C3E50;
	margin-bottom: 10px;
	letter-spacing: 2px;
	font-weight: bold;
}

.fs-subtitle {
	font-weight: normal;
	font-size: 13px;
	color: #666;
	margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
	margin-bottom: 30px;
	overflow: hidden;
	/*CSS counters to number the steps*/
	counter-reset: step;
}

#progressbar li {
	list-style-type: none;
	color: #666;
	text-transform: uppercase;
	font-size: 9px;
	width: 8%;
	float: left;
	position: relative;
	left: 29%;
	letter-spacing: 1px;
}

@media only screen and (max-width : 1023px) {
	#progressbar li {
		list-style-type: none;
		color: #666;
		text-transform: uppercase;
		font-size: 9px;
		width: 20%;
		float: left;
		position: relative;
		left: -5%;
		letter-spacing: 1px;
	}
}

#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 24px;
	height: 24px;
	line-height: 26px;
	display: block;
	font-size: 12px;
	color: #333;
	background: white;
	border-radius: 25px;
	margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
	content: '';
	width: 100%;
	height: 2px;
	background: white;
	position: absolute;
	left: -50%;
	top: 9px;
	z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
	/*connector not needed before the first step*/
	content: none;
}

/*marking active/completed steps blue*/
/*The number of the step and the connector before it = blue*/
#progressbar li.active:before, #progressbar li.active:after {
	background: #3f8efc;
	color: white;
}

.row1 label {
	display: inline-block;
	padding: 8px 10px;
	margin: 10px;
	background-color: white;
	border: none;
	cursor: pointer;
}

.radio-group {
	display: flex;
	flex-wrap: wrap;
}

.radio-wrapper {
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-bottom: 10px;
}

.radio-wrapper input[type="radio"] {
	margin-right: 5px;
}

@media ( max-width : 375px) {
	.radio-group {
		flex-direction: column;
	}
	.radio-wrapper {
		margin-right: 0;
	}
}

.checkbox-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	text-align: left !important;
}

.checkbox-pair {
	flex-basis: 45%;
	margin-bottom: 10px;
}

.checkbox-pair input[type="checkbox"] {
	margin-right: 5px;
}

#toastContainer {
	position: fixed;
	top: 20px;
	right: 20px;
	left: 20px;
	z-index: 9999;
}

.toast {
	background-color: #333;
	color: #fff;
	padding: 10px 20px;
	border-radius: 4px;
	margin-bottom: 10px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.toast.show {
	opacity: 1;
}

.whatsapp-button {
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 99;
	background-color: #25d366;
	border-radius: 50px;
	color: #ffffff;
	text-decoration: none;
	width: 50px;
	height: 50px;
	font-size: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
	-moz-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
	box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
	animation: effect 5s infinite ease-in;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
	.d-flex {
		display: block !important;
	}
}

.img-thumbs {
	background: #eee;
	border: 1px solid #ccc;
	border-radius: 0.25rem;
	margin: 1.5rem 0;
	padding: 0.75rem;
}

.img-thumbs-hidden {
	display: none;
}

.wrapper-thumb {
	position: relative;
	display: inline-block;
	margin: 1rem 0;
	justify-content: space-around;
}

.img-preview-thumb {
	background: #fff;
	border: 1px solid none;
	border-radius: 0.25rem;
	box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.12);
	margin-right: 1rem;
	max-width: 200px;
	padding: 0.25rem;
}

.remove-btn {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: .7rem;
	top: -5px;
	right: 10px;
	width: 20px;
	height: 20px;
	background: white;
	border-radius: 10px;
	font-weight: bold;
	cursor: pointer;
}

.remove-btn:hover {
	box-shadow: 0px 0px 3px grey;
	transition: all .3s ease-in-out;
}