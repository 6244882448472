#property-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bg-lightblue {
    background-color: #ecf1f6;
    border-radius: 25px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

}

.selected-tab {
    background-color: #fff;
    border: none;
    padding: 10px 0;
    color: #287DFD;
    border-bottom: 1px solid #287DFD;
}

#contactOwner-pp {
    color: white !important;
    background-color: #007bff !important;
    border: none !important;
    margin-top: 15px;
}

#contactOwner-pp a {
    text-decoration: none;
    color: white !important;
}

#sendMessage-pp {
    margin-top: 15px;
    color: #007bff !important;
    background-color: white !important;
    border: 1px solid #007bff !important;
}

#similarPropertyHeading h3 {
    position: relative;
    margin: 0px 0px 40px 0px;
    height: 25px;
    font-size: 20px;
    /* line-height: 131.02%; */
}

#similarPropertyHeading h3::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 0%;
    width: 100px;
    border: 1px solid #3F8EFC;
    border-radius: 25px;
}

.unselected-tab {
    background-color: #fff;
    border: none;
    padding: 10px 0;
}

#propertyPhotos {
    min-height: 50vh;
    margin-top: 20px;
}

#SecondaryImageBox div {
    min-height: 48%;
}

#SecondaryImageBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 10px;
}

.AmenityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
}

.modal-controls .AmenityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
}

.imageViewer {
    max-width: 100%;
    max-height: 90% !important;
    z-index: 6;
}

.overlay-text {
    color: white;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}

#propertyDetails {
    margin-top: 1.5rem;
}

.detailsBox {
    background-color: #fff;
    margin: 1.5rem 0;

}

.innerDetailsBox {
    padding: 1rem 2rem;
}

.innerDetailsBox.d-flex.flex-wrap{

    gap: 20px;
}

.boxHeader {
    padding: 1rem 2rem;
    font-size: 20px;
    color: black;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}

.labelTextForDetail {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
}

.valueTextForDetail {
    /* font-weight: bold; */
    color: rgba(0, 0, 0, 0.8);
    line-height: 2;
}

.amenitiesBox {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    align-items: center;
}

@media screen and (max-width:768px) {
    #SecondaryImageBox {
        padding-left: 0 !important;
    }

    .AmenityBox {
        padding: 10px;
    }

    #addressHeading {
        font-size: large;
    }

    .pricingLabel {
        font-size: 13px;
    }

    .amenitiesBox {
        padding: 10px;
        flex-wrap: wrap;
        font-size: 12px;
    }

    .labelTextForDetail {
        margin-top: 0px;
        font-size: 13px;
    }

    .valueTextForDetail {
        line-height: 1.2;
        font-size: 13px;
    }

    .detailsBox {
        margin-top: 0;
        /* padding: 30px 0; */
    }

    .innerDetailsBox {
        padding: 1.5rem 1rem;
        color: rgba(0, 0, 0, 0.8);
    }

    .boxHeader {
        padding: 1rem 2rem;
        font-size: medium;
    }

    .SingleDetailBox {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
    }

    .valueTextForDetail {

        width: auto !important;
        margin-top: 0px !important;
        padding: 5px 5px !important;
    }
}

@media only screen and (min-width:1024px) and (max-width:1200px) {
    .innerDetailsBox{
        padding: 0.5rem;
    }
}
.AmenityBox span{
    text-align: center;
}

.phone-width img {
    object-fit: cover;
}


.phone-width .card-body {
    border-radius: 0 0 5px 5px !important;
    background-color: white;
}

.featuresRow .card {
    margin: 10px 10px 35px 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.left-photo img {
    object-fit: cover;
}

.left-photo {

    max-height: max-content !important;
    width: 40%;
    /* background: white; */
    margin-top: 27px;
    display: flex;
    /* justify-content: center; */
    background-color: white !important;
    align-items: center;
    flex-direction: column;
    height: 100% !important;
}

/*  */
@media screen and (max-width: 767px) {
    .modal {
        padding-left: 0 !important;
    }

    .bg-lightblue {
        background-color: #ffffff !important;
        border-radius: 25px;
        box-shadow: none;
    }

    .bg-lightblue {
        padding: 0;
    }

    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 0;
    }

    #property-heading h2 {
        text-transform: uppercase;
        font-size: 12px;
        font-size: 16px !important;
        font-weight: bold;
        color: BLACK;
        letter-spacing: 2px;
        line-height: 29px;
        background: transparent;
        box-shadow: none;
        padding: 7px 0;
        margin-left: 14px;
        /* box-shadow: rgb(0 0 0 / 5%) 0px 3px 8px; */
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        color: #000000d6;
        border: 1px solid transparent;

    }

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 42px 16px;
        pointer-events: none;
    }
}
.ShareIcons {
    cursor: pointer;
    background: white;
    width: 40px;
    height: 40px;
    display: flex;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    align-items: center;
    border-radius: 50%;
    margin-right: 0;
}

.SingleDetailBox .valueTextForDetail ul{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    margin-bottom: 0;
    margin-top: 0;


}


.SingleDetailBox .valueTextForDetail{
    width: 100%;
    padding: 10px 0px;
    /* border: 1px solid #00000033; */
    margin-top: 8px;
    border-radius: 6px;
}
/* .valueTextForDetail ul li{
    position: relative;
}
.valueTextForDetail ul li::after {
    content: "";
    display: block;
    width: 2px;
    background: #0000001a;
    height: 21px;
    position: absolute;
    top: 6px;
    right: 10px;
} */

.SingleDetailBox .bg-property .propert-preference li{
    border: 1px solid #e9ecef;
    border-radius: 20px;
    padding: 8px 21px;
    background: #e9ecef;
    margin: 0 5px 0 0px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.SingleDetailBox .bg-property .propert-preference{
    list-style: none;
    display: flex;
    padding-left: 0;
}
.SingleDetailBox .bg-property{
    border: none;
    padding: 0;
}
.modal-controls {

    display: none;
}
.flatseeker-container .propert-preference {

    flex-wrap: wrap !important;
}

.flatseeker-container .budget-row {

    display: grid;
grid-template-columns: repeat(3, 1fr);
}
.flatseeker-container .SingleDetailBox {

    flex-direction: column;
}

.flatseeker-container .flat-details span {
    font-size: 12px;
    text-align: center;
}

.flatseeker-container .detailsBox {

    margin-top: 0px;
}
.flatseeker-container .left-photo {

    margin-top: 0px;
}
.flatseeker-container #property-heading h2 {
    padding: 15px 30px 15px 0px;

}

.flatseeker-container #property-heading h2 {
    padding: 15px 30px 15px 0px !important;

}

.flatseeker-container #property-heading h2 {
   
    text-transform: capitalize !important;
}

.SingleDetailBox .bg-property .propert-preference li {

    text-transform: capitalize;
}



/* new css code */
@media screen and (max-width: 1399px) and (min-width: 1201px){

    .flatseeker-container .SingleDetailBox .valueTextForDetail ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .flatseeker-container #property-heading h2 {
        padding: 15px 30px 15px 0px !important;
    
    }

}
@media screen and (max-width: 1200px) and (min-width: 992px){
    
    .nav-elements ul a {
        font-size: 12px;
        font-weight: 350;
        color: #333333;
        text-decoration: none;
    }

}
@media screen and (max-width: 992px) and (min-width: 768px){
    .flatseeker-container{
        margin: 0px !important;
        width: 100% !important;
        max-width: 100%;
        padding-top: 0px !important;
    }

    .flatseeker-container .SingleDetailBox {

        flex-direction: column;
    }

    .flatseeker-container .budget-row {

        display: grid;
    grid-template-columns: repeat(3, 1fr);
    }

    .flatseeker-container .left-photo {

        margin-top: 0px;
    }
    





    .flatseeker-container .left-photo img {
        width: 150px;
        height: 150px;
        object-position: top center;
        border-radius: 50%;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 4px;
    }

    
    .flatseeker-container #property-heading h2 {
        padding: 15px 30px 15px 0px;
        font-size: 21px !important;
    }

    .flatseeker-container #IconGroup {
        display: block;
        display: flex;
        gap: 10px;
    }

    .flatseeker-container .SingleDetailBox .valueTextForDetail ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .flatseeker-container .innerDetailsBox {
        padding: 15px;
    }

  


}
@media screen and (max-width: 767px) and (min-width: 200px){
    .step-4-photos::-webkit-scrollbar-track {
        background: rgb(105, 105, 105);
    }  .step-4-photos::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
    }

    .step-4-photos::-webkit-scrollbar-thumb {
        background: #287dfd;
    }

    .modal-controls {

        display: block;
    }

    .flatseeker-container #property-heading h2 {
        padding: 15px 30px 15px 0px;
        font-size: 14px !important;
        text-align: center;
    }

    .flatseeker-container #property-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    


    .modal-controls .mobileAndTab-hide {
        display: block !important;
    }


    .flatseeker-container #CarouselImage {
        height: 150px;
        width: 150px;
        border-radius: 50% !important;
        object-fit: cover;
        object-position: top;
    }

    .flatseeker-container #IconGroup {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-bottom: 15px;
    }

    
    
    .modal-controls  .left-photo {
    
        width: 100% !important;
        margin: 0px !important;
    }
    .modal-controls  .left-photo img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 4px;
        margin: 0px !important;

    }
    
    
    .modal-controls #property-heading h2 {
        text-transform: uppercase;
        font-size: 12px;
        font-size: 16px !important;
        font-weight: bold;
        color: BLACK;
        letter-spacing: 2px;
        line-height: 29px;
        background: transparent;
        box-shadow: none;
        padding: 7px 0;
        /* box-shadow: rgb(0 0 0 / 5%) 0px 3px 8px; */
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        color: #000000d6;
        border: 1px solid transparent;
        text-align: center;
    }

    .modal-controls .innerDetailsBox {
        padding: 15px !important;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 0px  !important;
    }
    .modal-controls .boxHeader {
        padding: 15px !important;
        font-weight: 700 !important;
        font-size: 14px !important ;
    }

    .modal-controls  .right.photo {
    
        width: 100% !important;
        margin: 0px !important;
    }

    .modal-controls .SingleDetailBox {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .modal-controls .SingleDetailBox .valueTextForDetail ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .modal-controls .labelTextForDetail {
        /* margin-top: 0px; */
        font-size: 15px;
        font-weight: 600;
        margin: 6px 0px;
    }

    .modal-controls .LaptopHide{

        display: none;
    }


}

/* @media screen and (min-width:768px){
    .LaptopHide{
        display: block !important;
    }
} */
