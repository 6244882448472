.login-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 60;
  align-items: center;
  justify-content: center;
}

.login-modal.open {
  display: flex;
}
.modal-content form {
  background-color: white;
  border-radius: 20px;
  /* width: 400px; */
}
.modal-content .expire-model {
  background-color: #000000cf;
  border-radius: 20px;
  width: 400px;
}
.titleBox {
  text-align: center;
  padding: 15px 10px 0px;
}
.subheading {
  padding: 0 40px;
  font-weight: 100;
  font-family: sans-serif;
  font-size: 15px;
  opacity: 0.7;
}
.titleBox div h2 {
  /* width: 500px; */
  width: 100%;
  text-align: center;
}
.titleBox div span {
  top: 0;
}
.inputBox {
  padding: 0px 50px 50px 50px;
}
.close {
  /* margin: 10px; */
  margin-right: 50px;
  margin-top: 15px;
  font-size: 18px;
  cursor: pointer;
}
.closeBox {
  float: right;
  /* max-height: 18px; */
  display: flex;
  flex-direction: row-reverse;
}
label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}
.brandBtn {
  background-color: white !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  padding: 0px 10px !important;
}
.brandBtn img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 0 !important;
}
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  width: 100%;
  padding: 8px;
  padding-right: 15px;
  border: 1px solid #ccc;
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
}
.formtext {
  margin-top: 3px !important;
  font-size: smaller;
  display: flex;
  flex-direction: row-reverse;
  font-size: 13px !important;
}
.formtext span {
  text-decoration: underline;
  font-weight: bold;
}
.modal-content form button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
}

.login-with-google {
  margin-top: 20px;
  text-align: center;
}

.login-with-google p {
  margin-bottom: 10px;
}

.login-with-google button {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .inputBox {
    padding: 20px;
  }
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  textarea,
  select {
    padding: 5px;
    margin-top: 10px;
  }
  .titleBox {
    padding: 0px 10px 0px;
  }
  .closeBox {
    margin-right: -30px;
  }
}
/* Add these styles to your CSS file */
.owner-details {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.owner-details p {
  margin: 5px 0;
  font-size: 16px;
}

.owner-details p:first-child {
  font-weight: bold;
}

.owner-details p:last-child {
  margin-bottom: 0;
}

.filter-modal {
  display: none;
  position: absolute;
  top: 7vh;
  overflow-y: auto;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 60;
}

.filter-modal.open {
  display: flex;
}
.text-hover:hover {
  color: black !important;
}
.text-clicked {
  color: #8e8e8e; /* Change the color to your preferred style */
  text-decoration: underline; /* Add an underline */
  cursor: default; /* Change the cursor style to indicate it's not clickable */
}
.custompopup-heading {
  color: #2f1c6a;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}
.expire-heading {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.circle-center {
  margin: auto;
  width: 30%;
}
.circle {
  width: 90px;
  height: 90px;
  border: 10px solid rgba(13, 110, 253, 0.94); /* Adjust border properties as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
}

.custom-number {
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
}
.expire-description {
  position: absolute;
  top: 30%;
  left: 46%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 40px;
}
@media (min-width: 768px) {
  .expire-description {
    position: absolute;
    top: 28%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 40px;
  }
}
.btn-own10 {
  border: 3px solid #ffc107;
  color: white;
  margin-top: 30px;
  padding: 5px 50px;
  border-radius: 6px;
  background-color: #ffc107;
}
.expire-close {
  /* margin: 10px; */
  margin-right: 50px;
  margin-top: 15px;
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
}