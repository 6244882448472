@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: Open Sans, Arial, Helvetica, sans-serif !important;
  background-color: #287dfd;
}


.Navcontainer {
  min-width: 98vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 50px; */
  padding: 0 50px;
}
#premium {
  display: none;
}
nav {
  height: 85px;
  background-color: white;
  z-index: 5;
}
#logoBox {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.menu-icon {
  display: none;
}
.navbar img {
  width: 50px !important;
  height: 40px !important;
  margin-right: 10px;
  /* margin-top: 10px; */
}
.navbar-text {
  padding: 0 !important;
}
.nav-elements ul li :hover{
  scale: 1.02;
}
.nav-elements ul li {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.nav-elements ul {
  display: flex;
  margin-bottom: 0;
  /* justify-content: center; */
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 18px;
  font-weight: 350;
  color: #333333;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #287dfd;
  font-weight: 500;
  position: relative;
}

#Myaccount {
  display: none;
}
.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #287dfd;
}
.rightBox {
  float: right;
}
.loginBtnMobileView {
  width: 150px;
}
#loginBtn {
  background-color: #fff !important;
  color: #287dfd;
  border: 1px solid #287dfd;
  width: 100px;
}
#loginBtn:hover {
  background-color: #287dfd !important;
  color: #fff;
}
#registerBtn {
  background-color: #287dfd !important;
  border: 1px solid #287dfd;
  width: 100px;
}
#registerBtn:hover {
  background-color: #fff !important;
  color: #287dfd;
  border: 1px solid #287dfd;
}

/*  for login modal */
.loginModal {
  border-radius: 25% !important;
}
@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}
@media (max-width: 1280) and (min-width: 1080px) {
  .nav-elements ul a {
    font-size: 16px;
  }
}
@media (max-width: 1360px) and (min-width: 1023px) {
  .Navcontainer {
    padding: 0 5px;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 15px;
  }
}
@media (max-width: 1023px) {
  .menu-icon {
    display: block;
    z-index: 501;
    cursor: pointer;
    border: 1px solid #007bff;
    padding: 0.35rem 0.75rem;
  }
  #Myaccount {
    display: flex;
    font-size: 18px;
    font-weight: 350;
    color: #333333;
    text-decoration: none;
  }
  #premium {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin-right: 30px;
    background-color: #30363c;
  }
  .Navcontainer {
    /* justify-content: space-around; */
    padding: 0 10px;
    padding-left: 20px;
  }
  .rightBox {
    display: none;
  }
  .nav-elements {
    position: fixed;
    right: 0;
    top: 0px;
    padding-top: 60px;
    min-height: 100vh;
    width: 0;
    background-color: #fffffff5;
    z-index: 500;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: 30px;
    padding: 10px 0;
    justify-content: left;
    border-bottom: 0.5px solid #706f6f52;
  }
  .listProperty {
    border-bottom: none !important;
  }
  #MyaccountDiv li a {
    font-size: 16px !important;
    margin-left: 30px;
  }
  .dropdown-content {
    right: 0;
  }
}
.dropbtn {
  border: 1px solid rgb(90, 90, 90);
  color: black;
  padding: 16px;
  font-size: 16px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: -10px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #287dfd;
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #287dfd;
  border: none;
  color: white;
}