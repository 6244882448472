* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-family: Open Sans, Arial, Helvetica, sans-serif !important;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px;
  }
}

.navbar img {
  width: 70px;
  height: 65px;
  margin-right: 10px;
  /* 	margin-top: 10px;
 */
}

.navbar-text {
  font-size: 30px;
  font-weight: bold;
  color: #287dfd !important;
}

.navbar-subtext {
  font-size: 14px;
  color: #888;
  margin-top: -22px;
  margin-left: 87px;
}

@media only screen and (min-width: 200px) and (max-width: 375px) {
  .navbar-subtext {
    color: #888;
    margin-top: -21px;
    margin-left: 90px;
    font-size: 8px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .navbar-subtext {
    font-size: 11px;
    color: #888;
    margin-top: -21px;
    margin-left: 89px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
  margin-right: 10px;
  font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
  border-bottom: 2px solid #287dfd;
}

@media only screen and (min-width: 1439px) {
  .navbar-light .navbar-nav .nav-link {
    color: black !important;
    margin-right: 10px;
    font-size: 20px !important;
  }
}

.button-look {
  border: 2px solid #287dfd;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  padding-left: 10px !important;
}

.button-look:hover {
  background: #287dfd;
  color: white !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.375rem;
  margin: 10px;
}

.img-thumbnil {
  border-radius: 0.375rem 0.375rem 0 0;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .img-thumbnil {
    border-radius: 0.375rem 0.375rem 0 0;
    height: 200px;
  }
}

.bg-img {
  /* The image used */
  background-image: url("https://wallpapers.com/images/featured/a5u9zq0a0ymy2dug.jpg");
  min-height: 380px;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Needed to position the navbar */
  position: relative;
}
/* Position the navbar container inside the image */
/* .face {
	position: absolute;
	margin: 20px;
	width: auto;
} */

/* Default styles for the form */
.property-detail label {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #ccc;
  color: #333;
}
/* 
input[type="radio"]:checked+label {
	background-color: #ccc;
	color: #fff;
}

* input[type="radio"] {
	display: none;
}

input[type="radio"]+span {
	display: inline-block;
	border: 1px solid #ddd;
	padding: 5px 10px;
	cursor: pointer;
}

input[type="radio"]:checked+span {
	background-color: #007bff;
	color: #fff;
} */

/* section {
	width: 100%;
	max-width: 600px;
	adjust as per your design
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
} */
@media (max-width: 768px) {
  /* adjust breakpoint as per your design */
  section {
    max-width: 100%;
  }
}

textarea {
  width: 560px;
}

@media only screen and (max-width: 320px) {
  textarea {
    width: 280px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  textarea {
    width: 335px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  textarea {
    width: 385px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  textarea {
    width: 730px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  textarea {
    width: 565px;
  }
}

/* .navbar-collapse {
	flex-grow: 0 !important;
	align-items: center;
}
 */
.image-bg {
  background: url("https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVhbCUyMGVzdGF0ZXxlbnwwfHwwfHw%3D&w=1000&q=80");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  font-family: Open Sans, Arial, Helvetica, sans-serif !important;
  color: #384047;
}

form {
  max-width: 300px;
  margin: 10px auto;
  border-radius: 8px;
}

h1 {
  margin: 0 0 30px 0;
  text-align: center;
}
.carouselImage {
  width: 100%;
}
.custom-search-bar {
  display: flex;
  align-items: baseline;
  width: 80%;
  /* height: 60px; */
  font-size: 15px !important;
  padding-left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.345);
  margin-top: 1.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex !important;
  justify-content: center !important;
}
#searchInput {
  margin: 0;
  border: none;
}
#searchInput:focus {
  border: none;
  outline: none;
}
.search-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #888;
}
#FontAwesomeIcon {
  animation: zoom 1s infinite;
}
@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.search-button {
  background-color: #007bff;
  color: white;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  /* margin-right: 2px;
  margin-top: 2px; */
}

/* 
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"],
	input[type="email"], input[type="number"], input[type="search"], input[type="tel"],
	input[type="time"], input[type="url"], textarea, select {
	background: rgba(255, 255, 255, 0.1);
	border: none;
	font-size: 16px;
	height: auto;
	margin: 0;
	outline: 0;
	padding: 15px;
	width: 100%;
	background-color: #e8eeef;
	color: #8a97a0;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
	margin-bottom: 30px;
}

input[type="radio"], input[type="checkbox"] {
	margin: 0 4px 8px 0;
}

select {
	padding: 6px;
	height: 32px;
	border-radius: 2px;
} */

.button-design {
  padding: 19px 39px 18px 39px;
  color: #fff;
  background-color: #4bc970;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #3ac162;
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
}

fieldset {
  margin-bottom: 30px;
  border: none;
}

legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 8px;
}

label.light {
  font-weight: 300;
  display: inline;
}

.number {
  background-color: #5fcf80;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

@media screen and (min-width: 480px) {
  form {
    max-width: 480px;
  }
}

.product {
  display: flex;
  column-gap: 1.2rem;
  padding: 30px 20px;
  height: 570px;
}

.product-small-image {
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 530px;
  /* 	justify-content: space-between;
 */
}

.product-small-image img {
  width: 100%;
  height: 130px;
  cursor: pointer;
  object-fit: cover;
  filter: brightness(50%);
  transition: all 0.5s ease-out;
  padding: 1px 0;
}

.product-small-image img:hover {
  cursor: pointer;
}

.product-small-image img.active {
  transform: scale(0.9);
  filter: none;
  border: solid 4px #118ab2;
}

/* Shrink */
.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.7s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.product-big-image {
  width: 100%;
  height: 520px;
}

.product-big-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.product-big-image img.fade-out {
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .product {
    display: none;
  }
  .custom-search-bar {
    width: 100%;
    font-size: 12px;
  }
  .search-icon {
    margin-right: 8px;
    font-size: 18px;
  }
  .search-button {
    padding: 0.8rem 1.2rem;
  }
  .carouselImage {
    width: 70%;
  }
}

.text-color {
  color: #575757;
}

.w-200px {
  width: 170px;
}

.w-10px {
  width: 10px;
}

.img-30 {
  max-width: 30px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.amenitites-design {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  width: 33%;
  margin-bottom: 1.5rem;
  align-items: center;
}

.link_design_hide {
  text-decoration: none;
  color: black;
}

.top-left {
  position: absolute;
  left: 20px;
  top: 0px;
  background-color: #00acee;
  padding: 0px 15px;
  color: white;
}

.row1 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.content-design {
  background: #00acee;
  color: #fff !important;
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  font-size: 0.65rem;
}

.content-design1 {
  background: #00acee;
  color: #fff !important;
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  font-size: 0.65rem;
}

@media (max-width: 375px) and (min-width: 200px) {
  .row1 {
    flex-wrap: wrap;
  }
  .row1 .price-design {
    width: 100%;
    position: relative;
    left: 8px;
    top: 7px;
  }
  .row1 p {
    margin-bottom: 0px;
  }
}

.price-design {
  position: absolute;
  right: 16px;
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .icon-bottom {
    margin-bottom: 20px !important;
  }
}

.content-font {
  font-size: 0.875rem;
  color: rgba(55, 65, 81, 0.8);
}

.margin-20 {
  margin-left: 20px;
}

.d-flex {
  display: flex;
}
.p-flex {
  display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .d-flex {
    display: block !important;
  }
  .wid-67 {
    width: auto !important;
  }
  .wid-33 {
    width: auto !important;
  }
  .stick-position {
    position: inherit !important;
    margin-left: 0px !important;
    margin-top: 40px !important;
  }
}

.d-col {
  display: flex;
  flex-direction: column;
}

.wid-67 {
  width: 67%;
}

.wid-33 {
  width: 33%;
}

.stick-position {
  position: sticky;
  margin-left: 20px;
  margin-top: 20px;
}

.text-justify {
  text-align: justify;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .text-justify {
    text-align: center;
  }
  .p-flex {
    display: block;
    text-align: center !important;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .align-center {
    text-align: center !important;
  }
}

.w-130px {
  width: 130px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.text-heading {
  color: #333 !important;
  font-size: larger;
}

.text-heading1 {
  color: #333 !important;
  font-size: 1.1rem;
}

.fs-14 {
  font-size: 14px !important;
}

.pr-2,
.px-2 {
  padding-right: 0.625rem !important;
}

.divider {
  margin: 10px 0;
}

.divider-2 {
  margin: 20px 0;
}

.row1 {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .margin-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .amenities-hide {
    display: none;
  }
}

@media only screen and (min-width: 576px) {
  .amenities-show-more {
    display: none;
  }
}

@media only screen and (min-width: 576px) {
  .billing-hide {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .mobile-hide {
    display: none !important;
  }
}

.amenities-show-more {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 426px) {
  .long-description {
    max-height: 100px; /* Adjust the height as needed */
    overflow: hidden;
    text-align: justify;
    text-overflow: ellipsis;
  }
  .show-more-button1 {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  .show-less-button1 {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
}

.bullet {
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 4px solid #6c757d;
  border-radius: 50%;
  background-color: #6c757d;
  margin-right: 7px;
  position: relative;
  top: -2px;
}

.bullet:before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px solid #6c757d;
  border-radius: 50%;
}

.table-wrapper th,
.table-wrapper td {
  padding: 0.5rem 0.2rem;
}

.heading-with-share {
  display: flex;
  align-items: center;
}

.share-button {
  /* 	margin-left: auto;
 */
  margin: 10px 2px 0 auto;
}

body {
  background-color: #f5f5f5;
}

.white-bg {
  background-color: white;
  padding: 20px;
  margin: -2px;
}

.btn-outline-main {
  background-color: #3f8efc;
  color: white;
}

.btn-outline-main:hover {
  color: #3f8efc;
  border-color: #3f8efc;
  background-color: white;
}

.extra-option {
  margin: 5px 0 0 5px;
}

.extra-option dt,
dd {
  color: #6c757d;
}

#msg {
  color: #6c757d;
}

@media only screen and (min-width: 1024px) and (max-width: 2000px) {
  .amenities-size {
    width: 30px;
    height: 30px;
  }
  .amenities-spacing {
    margin-left: 1px;
  }
}
@media only screen and (max-width: 1024px) {
  .amenities-size {
    width: 30px;
    height: 30px;
  }
  .amenities-spacing {
    margin-left: 1px;
  }
}

.amenities-popup-row {
  font-size: xx-small;
  margin: 0 2px;
  padding: 2px 0;
  display: flex;
  flex-wrap: wrap;
}

.carousel1 {
  position: relative;
  order: 1;
}

@media (max-width: 1000px) {
  .card-group2 {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .card {
    flex: 0 0 auto;
    /* width: 300px; */
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .card-group2 {
    display: flex;
  }
}

.card-group1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* flex-wrap: nowrap;
	overflow-x: scroll; */
}
.card1 {
  flex: 0 0 auto;
  width: 20%;
  margin: 0 10px;
  border-radius: 25px;
  position: relative;
}
#premiumBox {
  min-height: 50vh;
  display: flex;
  background-color: #333;
  padding: 3rem 6rem;
  margin-top: 3rem;
}
.premiumText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 15rem;
}
.premiumText h1 {
  color: #ffc107;
  text-align: left;
  line-height: 1.5;
}
.premiumText h5 {
  color: white;
  margin-top: 15px;
}
.premiumText button {
  background-color: #287dfd;
  border: none;
  padding: 10px 50px;
  margin-top: 15px;
  color: white;
  font-size: 20px;
}
.premiumPhoto img {
  /* display: none; */
  width: 400px;
  margin: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .premiumPhoto img {
    width: 220px;
    margin: 10px;
  }
  .premiumText h1 {
    color: #ffc107;
    text-align: left;
    line-height: 1.5;
    font-size: 18px;
  }
  .premiumText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
  }
  .premiumText h5 {
    color: white;
    margin-top: 15px;
    font-size: 15px;
  }
  .premiumText button {
    background-color: #287dfd;
    border: none;
    padding: 2.5px 25px;
    margin-top: 15px;
    color: white;
    font-size: 20px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .premiumPhoto img {
    width: 300px;
    margin: 10px;
  }
  .premiumText h1 {
    color: #ffc107;
    text-align: left;
    line-height: 1.5;
    font-size: 29px;
  }
  .premiumText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
  }
  .premiumText h5 {
    color: white;
    margin-top: 15px;
    font-size: 20px;
  }
  .premiumText button {
    background-color: #287dfd;
    border: none;
    padding: 3px 30px;
    margin-top: 15px;
    color: white;
    font-size: 20px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .card1 {
    flex: 0 0 auto;
    width: 200px;
    margin: 0 5px 0 20px;
    border-radius: 25px;
    position: relative;
  }
  .card1 img {
    width: 100%;
    height: 120px !important;
    border-radius: 25px;
  }
  .card-group1 {
    display: flex;
    /* align-items: center; */
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    /* padding-left: 150px; */
  }

  .card-group1::-webkit-scrollbar {
    width: 1px;
    height: 2px;
  }

  .card-group1::-webkit-scrollbar-track {
    background-color: #ebebeb;
    border-radius: 10px;
  }

  .card-group1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #6d6d6d;
  }
  .premiumPhoto {
    display: none;
  }
  #premiumBox {
    min-height: 50vh;
    display: flex;
    background-color: #333;
    padding: 3rem 2rem;
  }
  .premiumText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  .premiumText h1 {
    text-align: center;
    font-size: 20px;
  }
  .premiumText h5 {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
  }
  .premiumText button {
    padding: 5px 15px;
    margin-top: 15px;
    font-size: 20px;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px) {
	.premiumPhoto img{
		width: 300px;
		margin: 10px;
	}
	#premiumBox{
		min-height: 50vh;
		display: flex;
		background-color: #333;
		padding: 3rem 2rem;
	}
	.premiumText{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-right: 0;
	}
	.premiumText h5{
		font-size: 15px;
		margin-top: 15px;
	}
	.premiumText button{
		padding: 5px 15px;
		margin-top: 15px;	
		font-size: 20px;
	}
} */

.card1 img {
  width: 100%;
  height: 175px;
  border-radius: 10px;
}

.card-title-overlay {
  position: relative;
  bottom: 35px;
  height: 35px;
  border-radius: 0px 0px 25px 25px;
  background: rgba(0, 0, 0, 0.5);
}

.card-title-overlay h5 {
  color: white;
  text-align: center;
  margin: 0;
  font-size: large;
}
.card-title {
  line-height: 2;
}
#custom-title .card-title {
  line-height: 1.2 !important;
}
@media only screen and (min-width: 200px) and (max-width: 374px) {
  .phone-width {
    width: 275px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 1023px) {
  .phone-width {
    width: 300px !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  /* .align-center {
		text-align: center;
	} */
  .text-justify1 {
    text-align: center !important;
  }
}

.heading {
  margin-bottom: 40px;
}

#special-heading {
  margin-bottom: 60px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  #special-heading h2 {
    position: relative;
    margin: 30px 0px -47px 22px;
    height: 46px;
    font-weight: 500;
    font-size: 20px;
    line-height: 131.02%;
  }
}

@media only screen and (min-width: 1439px) {
  #special-heading h2 {
    position: relative;
    margin: 50px 0px 40px 50px;
    height: 46px;
    font-weight: 500;
    font-size: 35px;
    line-height: 131.02%;
    text-align: center;
  }
  #special-heading h2::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 42%;
    width: 200px;
    border: 2.5px solid #3f8efc;
    border-radius: 25px;
  }
}

.heading h2 {
  position: relative;
  margin: 50px 0px 40px 50px;
  height: 46px;
  font-weight: 500;
  font-size: 35px;
  line-height: 131.02%;
}

.heading h2::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0px;
  width: 200px;
  border: 2.5px solid #3f8efc;
  border-radius: 25px;
}
#Features {
  min-height: 50vh;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
#featuresHeader {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
}
#featuresSubHeading {
  font-size: 1rem;
  color: #0088cc;
}
#featuresHeading {
  font-size: 2rem;
  color: rgb(40, 10, 111);
}
.featuresRow {
  display: flex;
  /* overflow-x: scroll; */
  /* justify-content: center; */
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.featuresRow::-webkit-scrollbar {
  width: 1px;
  height: 2px; /* Adjust the width as per your preference */
}

.featuresRow::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}

.featuresRow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #6d6d6d;
}
.card {
  min-width: 300px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
.card-body {
  border-radius: 0 0 5px 5px !important;
  background-color: whitesmoke;
}
.card-img-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.card-title {
  font-size: 15px;
}
.card-text {
  margin-bottom: 10px !important;
}
.formBox {
  background-color: rgba(128, 128, 128, 0.2);
  padding: 30px;
  width: 40vw;
  border-radius: 15px;
}
.textBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15rem;
}
.textBox h1 {
  text-align: left;
  font-weight: 600;
}
.RequestBox {
  display: flex;
  max-width: 1200px;
}
.RequestSection {
  background-color: hsl(225, 100%, 94%);
  padding: 50px 0;
  /* justify-content: center; */
}
.requestForm {
  width: 100% !important;
  padding: 8px !important	;
  padding-left: 15px;
  border: 1px solid #ccc;
  background-color: rgb(242, 237, 237);
  margin-top: 2px !important;
  border-radius: 1rem !important;
}
.requestBtn {
  background-color: #287dfd !important;
  color: white !important;
  width: 100%;
  border: none !important;
  padding: 10px 0 !important;
}
.mobileviewText {
  display: none;
}
#footerSocial {
  justify-content: space-between;
}
@media only screen and (min-width: 200px) and (max-width: 375px) {
  .heading h2 {
    position: relative;
    margin: 50px 0px 40px 22px;
    height: 46px;
    font-weight: 500;
    font-size: 22px;
    line-height: 131.02%;
  }
  .laptopviewText {
    display: none;
  }
  .mobileviewText {
    display: flex;
  }
  .heading h2::after {
    content: "";
    position: absolute;
    bottom: 13px;
    border: 1.7px solid #3f8efc;
    bottom: 13px;
    left: 0px;
    width: 100px;
  }
  #special-heading h2::after {
    content: "";
    position: absolute;
    border: 1.7px solid #3f8efc;
    bottom: 13px;
    left: 0px;
    width: 100px;
  }
  .RequestBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .formBox {
    width: 90vw;
  }
  .textBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
  }
  .textBox h1 {
    text-align: center;
    font-size: 25px;
    line-height: 1.5;
  }
  .textBox h5 {
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
  }
  #featuresHeading {
    font-size: 1.3rem;
  }
  .featuresRow {
    justify-content: flex-start;
  }
  .card {
    min-width: 250px !important;
    border-radius: 5px !important;
  }
  .card-title {
    font-size: 14px;
  }
  .card-text {
    margin-bottom: 6px !important;
    font-size: 12px;
  }
  #footerSocial {
    justify-content: center;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .heading h2 {
    position: relative;
    margin: 50px 0px 40px 22px;
    height: 46px;
    font-weight: 500;
    font-size: 20px;
    line-height: 131.02%;
  }
  .mobileviewText {
    display: flex;
  }
  .laptopviewText {
    display: none;
  }
  .heading h2::after {
    content: "";
    position: absolute;
    bottom: 13px;
    border: 1.7px solid #3f8efc;
    bottom: 13px;
    left: 0px;
    width: 100px;
  }
  .RequestBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .formBox {
    width: 90vw;
  }
  .textBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
  }
  .textBox h1 {
    text-align: center;
    font-size: 30px;
    line-height: 1.5;
  }
  .textBox h5 {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
  }
  #featuresHeading {
    font-size: 1.5rem;
  }
  .featuresRow {
    justify-content: flex-start;
  }
  .card {
    min-width: 300px !important;
    border-radius: 5px !important;
  }
  .card-title {
    font-size: 14px;
  }
  .card-text {
    margin-bottom: 6px !important;
    font-size: 13px;
  }
  #footerSocial {
    justify-content: center;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .heading h2 {
    position: relative;
    margin: 50px 0px 40px 22px;
    height: 46px;
    font-weight: 500;
    font-size: 20px;
    line-height: 131.02%;
  }
  .mobileviewText {
    display: flex;
  }
  .laptopviewText {
    display: none;
  }
  .heading h2::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 100px;
    border: 1.7px solid #3f8efc;
  }
  .RequestBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .formBox {
    width: 90vw;
  }
  .textBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
  }
  .textBox h1 {
    text-align: center;
    font-size: 32px;
    line-height: 1.5;
  }
  .textBox h5 {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
  }
  #featuresHeading {
    font-size: 1.7rem;
  }
  .featuresRow {
    justify-content: flex-start;
  }
  .card {
    min-width: 300px !important;
    border-radius: 5px !important;
  }
  .card-title {
    font-size: 14px;
  }
  .card-text {
    margin-bottom: 6px !important;
    font-size: 12px;
  }
  #footerSocial {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .heading h2 {
    position: relative;
    margin: 50px 0px -30px 50px;
    height: 46px;
    font-weight: 500;
    font-size: 30px;
    line-height: 131.02%;
  }
  .heading h2::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 150px;
    border: 1.7px solid #3f8efc;
  }
  .custom-search-bar {
    width: 80%;
    font-size: 13px;
  }
  .search-icon {
    margin-right: 8px;
    font-size: 18px;
  }
  .search-button {
    padding: 0.8rem 1.2rem;
  }
  .carouselImage {
    width: 70%;
  }

  .formBox {
    width: 40vw;
  }
  .textBox {
    padding-right: 2rem;
  }
  .textBox h1 {
    /* text-align: center; */
    font-size: 35px;
    line-height: 1.2;
  }
  .textBox h5 {
    /* text-align: center; */
    font-size: 15px;
    line-height: 1.5;
  }
  #featuresHeading {
    font-size: 1.8rem;
  }
  .featuresRow {
    justify-content: flex-start;
  }
  .card {
    min-width: 300px !important;
    border-radius: 5px !important;
  }
  .card-title {
    font-size: 15px;
  }
  .card-text {
    margin-bottom: 6px !important;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .small-txt {
    font-size: inherit;
  }
}

@media (max-width: 576px) {
  .platform-break {
    display: none;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .platform-break {
    display: none;
  }
}

#title {
  font-weight: 700;
  font-size: 50px;
}

#intro {
  font-weight: 700;
  font-size: 17px;
  margin: 15px 2px;
}
#info {
  overflow-x: hidden;
}
@media only screen and (min-width: 200px) and (max-width: 768px) {
  #title {
    font-weight: 700;
    line-height: 1.5;
    margin-top: 15px;
  }

  #info {
    margin-top: 10px !important;
  }
  .margin-top {
    margin-top: 30px;
  }
  .margin-top1 {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .why-text {
    font-size: 16px;
    margin: 0 30% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  #intro {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  #title {
    font-size: 23px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  #intro {
    font-weight: 700;
    font-size: 13px;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 0;
  }
  #title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #title {
    font-weight: 700;
    font-size: 30px;
  }
  #intro {
    font-weight: 700;
    font-size: 13px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  #title {
    font-weight: 700;
    font-size: 25px;
    padding-bottom: 10px;
  }
  #intro {
    font-weight: 700;
    font-size: 15px;
    margin-top: 10px;
  }
}

.text-justify1 {
  text-align: justify;
}

.btn-own {
  background: #287dfd;
  border-radius: 30px;
  width: 193px;
  color: white;
  margin-top: 50px;
  padding: 10px 30px;
}

.btn-own:hover {
  background: white;
  border: 2px solid #287dfd;
  color: #287dfd;
}

.btn-own1 {
  border: 3px solid #3f8efc;
  border-radius: 10px;
  /* width: 193px; */
  color: black;
  margin-top: 20px;
  padding: 5px 50px;
}

.btn-own2 {
  border: 3px solid #3f8efc;
  color: #3f8efc;
}

.btn-own1:hover {
  background: #3f8efc;
  color: white;
}

#info {
  margin-top: 80px;
  margin-bottom: 80px;
}

.title-design1 {
  margin: 0 0 20px -30px !important;
  font-size: 35px !important;
}

.bg-dark {
  background-color: #287dfd !important;
}

@media only screen and (min-width: 200px) and (max-width: 1024px) {
  .upper-spacing {
    margin-top: 70px;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 99;
  background-color: #25d366;
  border-radius: 50px;
  color: #ffffff;
  text-decoration: none;
  width: 125px;
  height: 50px;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
  animation: effect 5s infinite ease-in;
}
@media only screen and (min-width: 200px) and (max-width: 1023px) {
  .whatsapp-button {
    width: 100px;
    font-size: 20px;
  }
  .whats-icon {
    margin: 11px 5px !important;
    font-size: 25px !important;
  }
  .icon-flex p {
    margin: 8px 5px 5px 10px !important;
  }
}
.icon-flex {
  display: flex;
  flex-direction: row;
}
.icon-flex .whats-icon {
  margin: 20px 10px;
  text-decoration: none;
  color: white;
}
.icon-flex p {
  margin: 12px 2px 2px 10px;
  text-decoration: none;
  color: white;
  border: none;
}
.whatsapp-button a {
  text-decoration: none;
}

.whatsapp-button:hover {
  background-color: #287dfd;
  color: white;
}
.why-text {
  font-size: 16px;
  margin: 0 34px;
}

#info1 {
  /* margin-top: 100px; */
  /* margin-bottom: 100px; */
  background: #f5f5f5;
  padding: 50px 0;
  overflow-x: hidden;
}

#bg-color {
  background: white;
}

.margins {
  margin: 0 230px;
}

@media only screen and (min-width: 200px) and (max-width: 768px) {
  .margins {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .display-4 {
    font-weight: bold;
  }
}

@keyframes blink {
  0% {
    background-color: #287dfd;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #287dfd;
  }
}
.highlight {
  animation: blink 2s linear 4;
}

.view-modal,
.popup {
  position: absolute;
  left: 50%;
}

.view-modal {
  transform: translate(-50%, -50%);
}

.popup {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  top: -150%;
  max-width: 380px;
  width: 97%;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%) scale(1.2);
  transition: top 0s 0.2s ease-in-out, opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}

.popup.show {
  top: 50%;
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
  transition: top 0s 0s ease-in-out, opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}

.popup :is(header, .icons, .field) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebedf9;
}

header span {
  font-size: 21px;
  font-weight: 600;
}

header .close,
.icons a {
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

header .close {
  color: #878787;
  font-size: 17px;
  background: #f2f3fb;
  height: 33px;
  width: 33px;
  cursor: pointer;
}

header .close:hover {
  background: #ebedf9;
}

.popup .content {
  margin: 20px 0;
}

.popup .icons {
  margin: 15px 0 20px 0;
  padding: 0px 0px;
}

.content p {
  font-size: 16px;
}

.content .icons a {
  height: 50px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent;
}

.icons a i {
  transition: transform 0.3s ease-in-out;
}

.icons a:nth-child(1) {
  color: #25d366;
  border-color: #bef4d2;
}

.icons a:nth-child(1):hover {
  background: #25d366;
}

.icons a:nth-child(2) i {
  color: #000;
  padding: 9px 14px;
  border: 1px solid #bab2b2c9;
  border-radius: 50%;
}

.icons a:nth-child(2) i:hover {
  background: #383333e8;
  color: white;
}

.icons a:nth-child(3) {
  color: #1877f2;
  border-color: #b7d4fb;
}

.icons a:nth-child(3):hover {
  background: #1877f2;
}

.icons a:nth-child(4) {
  color: #0088cc;
  border-color: #b3e6ff;
}

.icons a:nth-child(4):hover {
  background: #0088cc;
}

.icons a:nth-child(5) {
  color: #46c1f6;
  border-color: #b6e7fc;
}

.icons a:nth-child(5):hover {
  background: #46c1f6;
}

.icons a:hover {
  color: #fff;
  border-color: transparent;
}

.icons a:hover i {
  transform: scale(1.2);
}

.overflow-stop {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-stop::after {
  content: "...";
  display: inline;
}

.img-thumbnail {
  padding: 0;
  border: none;
  border-radius: 0.25rem 0.25rem 0 0;
  max-width: 100%;
  height: 300px;
  background-color: transparent;
}

#accountform {
  max-width: 500px;
  background-color: white;
  padding: 20px;
}
.card1 a:link {
  text-decoration: none;
}

.card1 a:active {
  text-decoration: underline;
}
.small-text {
  font-size: small;
}
.terms-and-conditions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content {
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.5;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .page {
    width: 8cm;
    min-height: 29.7cm;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
.homepremium-text {
  color: #ffc107;
  font-size: 34px;
  text-align: left;
}
.premiumbox-size {
  margin-top: 50px;
  margin-left: 70px;
}
.homepremium-description {
  font-size: 16px;
  text-align: left;
  color: white;
  margin-top: 20px;
}
.homepremium-image img {
  width: 400px;
  height: auto;
  margin-top: -30px;
  margin-left: 150px;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .homepremium-text {
    font-size: 18px;
    text-align: center;
  }
  .homepremium-description {
    font-size: 12px;
    margin-top: 25px;
    text-align: center;
  }
  .homepremium-image img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-left: 0px;
  }
  #homepremium-hide {
    display: none;
  }
  .location-adjust {
    text-align: center;
  }
  .premiumbox-size {
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .homepremium-text {
    font-size: 22px;
    text-align: left;
  }
  .homepremium-description {
    font-size: 16px;
    margin-top: 25px;
    text-align: left;
  }
  .homepremium-image img {
    width: 180%;
    height: auto;
    margin-top: -25px;
    margin-left: 0px;
  }
  .premiumbox-size {
    margin-top: 0px;
    margin-left: 0px;
  }
}
.btn-own3 {
  border: 3px solid #287dfd;
  color: white;
  margin-top: 20px;
  padding: 5px 30px;
  background-color: #287dfd;
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .homepremium-text {
    font-size: 23px;
    text-align: left;
  }
  .homepremium-description {
    font-size: 14px;
    margin-top: 25px;
    text-align: left;
  }
  .homepremium-image img {
    width: 325px;
    height: auto;
    margin-top: -30px;
    margin-left: 150px;
  }
  .premiumbox-size {
    margin-top: 0px;
    margin-left: -20px;
  }
}
.btn-own3:hover {
  border: 3px solid #287dfd;
  color: #fff;
  background-color: #333;
}
@media only screen and (min-width: 1023px) {
  .extra-header {
    display: none;
  }
}
.iframe-set {
  width: 750px;
  height: 550px;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .iframe-set {
    width: auto;
    height: 800px;
  }
}

@media screen and (max-width: 767px) {
  form {
    max-width: 95%;
  }
  .location-groups {
    margin-bottom: 30px;
  }
}

.bg-lightblue {
  padding: 44px 39px;
}
.SingleDetailBox .valueTextForDetail {
  text-align: justify;
}
#property-heading h2 {
  /* text-transform: uppercase;
  font-size: 12px;
  font-size: 17px !important;
  font-weight: bold;
  color: BLACK;
  letter-spacing: 2px;
  background: radial-gradient(#ffffff, #007bff33);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgb(141 141 141 / 35%) 0px -2px 6px 0px inset; */

  padding: 15px 30px 15px 0px;

  /* box-shadow: rgb(0 0 0 / 5%) 0px 3px 8px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   color: #000000d6;
  border: 1px solid #0000001f; */
}
.left-photo {
  max-height: 300px;
  width: 300px;
  background: white;
  margin-top: 27px;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}
.left-photo img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 4px;
}

.autocomplete-dropdown-containers {
  position: absolute;
  background: white;
  border: 1px solid;
  width: 100%;
  scroll-behavior: smooth;
  padding: 5px;
  max-height: 100px;
  overflow-y: scroll;
  border-top: none;
  border-radius: 8px;
}

.d-none {
  display: none;
}

.area-box {
  position: relative;
}

.Container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  height: 100%;
}
.Container h3 {
  font-weight: bold;
}
.blog-bg {
  background-color: #f5f5f5;
}
