.signup-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.signup-modal.open {
  display: flex;
}
.modal-content form {
  background-color: white;
  border-radius: 20px;
  /* width: 400px; */
}
.titleBox {
  text-align: center;
  padding: 15px 10px 0px;
}
.subheading {
  padding: 0 40px;
  font-weight: 100;
  font-family: sans-serif;
  font-size: 15px;
  opacity: 0.7;
}
.titleBox div h2 {
  /* width: 500px; */
  width: 100%;
  text-align: center;
}
.titleBox div span {
  top: 0;
}
.inputBox {
  padding: 0px 50px 50px 50px;
}
.close {
  /* margin: 10px; */
  margin-left: 20px;
  margin-top: 8px;
  font-size: 18px;
  margin-bottom: 20px;
  color: #00000088;
  cursor: pointer;
}
.closeBox {
  float: left;
  /* max-height: 18px; */
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
}
label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}
.brandBtn {
  background-color: white !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  padding: 0px 10px !important;
}
.brandBtn img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 0 !important;
}
.homesearch input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  width: 100%;
  padding: 8px;
  padding-left: 15px;
  border: 1px solid #ccc;
  background-color: white;
  margin-top: 5px;
  border-radius: 5px;
}
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  width: 100%;
  padding: 8px;
  padding-left: 15px;
  border: 1px solid #ccc;
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
}
/* .location-search-inputs{
  border: 1px solid transparent !important;
  margin-top: 2px !important;
} */
.location-search-input,
.location-search-inputs {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #a0a0a0; /* Grey placeholder color */
}
.location-search-inputs::placeholder {
  color: #b3b3b3; /* Placeholder text */
}
.formtext {
  margin-top: 3px !important;
  font-size: smaller;
  display: flex;
  flex-direction: row-reverse;
  font-size: 13px !important;
}
.formtext span {
  text-decoration: underline;
  font-weight: bold;
}
.modal-content form button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
}
@media only screen and (max-width: 1023px) {
  .modal-content .area-add-button {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .modal-content .area-add-button {
    margin-top: 20px;
  }
}

.modal-content .area-add-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  width: 20%;
  cursor: pointer;
}

.signup-with-google {
  margin-top: 20px;
  text-align: center;
}

.signup-with-google p {
  margin-bottom: 10px;
}

.signup-with-google button {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .inputBox {
    padding: 20px;
  }
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  textarea,
  select {
    padding: 5px;
    margin-top: 10px;
  }
  .titleBox {
    padding: 0px 10px 0px;
  }
  .closeBox {
    margin-right: -30px;
  }
}
.text-hover:hover {
  color: black !important;
}
