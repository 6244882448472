.main {
  max-width: 100vw;
  overflow: hidden;
  background-color: #f7f7f9;
  padding-bottom: 50px;
}

.button {
  max-height: 52px;
}

#row {
  width: 100%;
}

.grey {
  color: rgba(0, 0, 0, 0.65);
}

.searchBar {
  min-width: 80%;
}

#filterBar {
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 8px;
  max-width: 21vw;
  background-color: #fff;
  height: fit-content;
  box-shadow: rgb(40 125 253 / 8%) 0px 7px 29px 0px;
  border: 1px solid #0000001a;
}

#filterBar form div label {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}

#filterBar form div input {
  margin-top: 0 !important;
}

.mainContain {
  background-color: #f7f7f9;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 20px 50px;
  font-size: medium;
}

.resultCount {
  color: gray;
  margin-bottom: 0 !important;
}

.multi-range-slider {
  box-shadow: none !important;
  border: none !important;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggle-text {
  margin: 0 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  /* Adjust the width */
  height: 17px;
  margin-bottom: 0 !important;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  /* Adjust the height */
  width: 13px;
  /* Adjust the width */
  left: 2px;
  /* Adjust the positioning */
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

.location-groups {
  display: flex;
  justify-content: left;
  gap: 5px;
  flex-wrap: wrap;
}
.location-groups input {
  background-color: #3f8efc;
  color: white;
}
.location-button {
  border: 1px solid #dcdcdc;
  padding: 4px 8px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.location-button:hover {
  background-color: #f0f0f0;
}

.location-button.selected {
  background-color: white;
  border: 1px solid #3f8efc;
  color: #3f8efc;
}

.location-label {
  position: relative;
  display: inline-block;
  margin: 0 !important;
  color: #000000b4;
  font-weight: 500 !important;
}

.location-label input[type="radio"] {
  position: absolute;
  left: 0;
  opacity: 0;
  /* Hide default location button */
  width: 0;
  height: 0;
}

.mobileAndTab-hide-1 {
  display: none;
}
.location-button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  /* Adjust the size of the custom circle */
}

.location-button.selected:before {
  background-color: #007bff;
  /* Customize the color for selected state */
  border-color: #007bff;
  /* Customize the border color for selected state */
}

.range-container {
  text-align: center;
}

.range-input {
  width: 80%;
  color: #007bff;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  width: 80%;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 5px;
}

#propertyCard-body {
  /* padding: 20px;
   */

  padding: 20px;
}

#propertyCard {
  background-color: #fff;
  margin-bottom: 30px;
}

#card-Heading {
  font-size: 22px !important;
  font-weight: normal;
  color: #30363c;
}

#card-location {
  color: rgba(0, 0, 0, 0.65);
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#Details {
  padding: 0 !important;
  display: flex;
}

#FlatseekerDetails {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

#card-Details {
  margin: 10px 0;
}

#emnities img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

#emnities {
  display: flex;
  flex-wrap: wrap;
}

#rentBox {
  font-size: 1.2rem;
  margin-left: 0px;
  margin-top: 10px;
}

#card-ButtonBox {
  background-color: #fff;
  padding: 25px 0;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#IconGroup {
  font-size: 19px;
  color: grey;
  font-weight: normal;
}

.heart {
  margin-right: 5px;
  cursor: pointer;
}

.ShareIcons {
  cursor: pointer;
}

.bookmarked {
  color: #287dfd;
}

.ownerBox span {
  color: rgba(0, 0, 0, 0.65);
}

.ownerBox {
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
  margin: 15px 0 !important;
  text-align: center;
}

#contact-owner {
  color: white !important;
  background-color: #007bff !important;
  border: none !important;
}

#contact-owner a {
  text-decoration: none;
  color: white !important;
}

#send-message {
  margin-top: 15px;
  color: #007bff !important;
  background-color: white !important;
  border: 1px solid #007bff !important;
}

.needHelp {
  font-size: 0.8rem;
  margin-top: 15px;
  text-align: center;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.65);
}

#GenderBox {
  display: none;
}

#iconBox {
  display: none;
}

#mobileviewHeader {
  display: none;
}

.mobilview-search-bar {
  display: flex;
  align-items: center;
  width: 55%;
  margin-right: 5px;
  padding-left: 10px;
  font-size: 14px;
  background-color: #fff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.mobileview-search-icon {
  margin-right: 5px;
  font-size: 12px;
  color: #888;
}

.mobileview-search-button {
  background-color: #007bff;
  color: white;
  border: none;
  justify-content: center;
  font-size: 15px;
  align-items: center;
  padding: 0.5rem 01rem;
  cursor: pointer;
}

.sortButton {
  cursor: pointer;
  border: 1px solid #007bff;
  padding: 0.35rem 0.5rem;
}

#CarouselImage {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

#CarouselVideo {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.reportBlock {
  position: absolute;
  z-index: 3;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 2px solid black;
  width: 200px;
  /* Set the width as per your design */
  background-color: #fff;
  /* Set the background color */
  border: 1px solid #ccc;
  /* Add a border for visual separation */
  padding: 10px 0;
  /* Add padding as needed */
}

.reportBlock ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 15px;
}

.reportBlock h6 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 10px !important;
}

.reportBlock ul li:hover {
  background-color: rgba(196, 195, 195, 0.269);
  scale: 1.02;
}

.reportBlock ul li {
  color: rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
}

.paginationBox {
  display: flex;
  justify-content: center;
  column-gap: 50px;
  margin-top: 37px;
}

.paginationBtn {
  background-color: #fff !important;
  border: 1px solid #287dfd !important;
  color: rgba(0, 0, 0, 0.744) !important;
  min-width: 100px;
}

.sortButton {
  display: none;
}
.active-city-btn {
  margin-left: 10px;
  padding: 8px 10px;
  border-radius: 50px;
  background: #287dfd15;
  border: 1px solid #287dfd;
  text-transform: capitalize;
}

.side-area-box .input-add-box {
  gap: 10px !important;
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  #CarouselImage {
    height: 210px;
  }

  #card-ButtonBox {
    flex-direction: row;
    padding: 0 15px;
    justify-content: space-between;
    margin: 10px 0;
    margin-bottom: 10px;
  }

  #contact-owner {
    border-radius: 8px;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  #CarouselImage {
    height: 210px;
  }

  #card-ButtonBox {
    flex-direction: row;
    padding: 0px 15px;
    justify-content: space-between;
    margin: 10px 0;
    margin-bottom: 10px;
  }

  #contact-owner {
    border-radius: 8px;
  }
}

@media screen and (min-width: 427px) and (max-width: 768px) {
  #card-ButtonBox {
    flex-direction: row;
    padding: 0px 15px;
    justify-content: space-between;
    margin: 10px 0;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 767px) and (max-width: 3000px) {
  .LaptopHide {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #card-Heading {
    font-size: 22px !important;
    line-height: 1.3;
    font-weight: normal !important;
  }

  #CarouselImage {
    height: 280px;
  }

  #contact-owner {
    border-radius: 25px;
  }

  #send-message {
    border-radius: 25px;
  }

  #propertyCard-body {
    padding: 20px 10px;
  }

  .ownerBox {
    margin: 5px 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .featuresRow .card-body {
    border-radius: 0 0 0px 0px !important;
    background-color: white;
  }

  .sortButton {
    display: block;
  }
  .featuresRow .card {
    flex: 0 0 auto;
    /* width: 300px; */
    margin-right: 10px;
    margin-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  #similarPropertyHeadingh3 {
    margin-top: 0 !important;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
    /* padding: 0; */
    border-radius: 15px;
    background: #f7f7f9;
  }

  .bg-lightblue .pertiflat-heading {
    text-transform: capitalize;
    justify-content: flex-start !important;
    font-size: 20px !important;
    margin-bottom: 10px;
  }

  #IconGroup {
    font-size: 12px;
    color: grey;
    font-weight: normal;
  }

  .modal-dialog {
    max-width: 100% !important;
  }

  .mobileAndTab-hide {
    display: none !important;
  }

  #mobileviewHeader {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .search-sortBar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2vw;
    justify-content: center;
  }

  .ShareIcons {
    height: 32px;
    width: 32px;
    line-height: 32px;
    background-color: rgb(242, 243, 248);
    border-radius: 50%;
    text-align: center;
  }

  #GenderBox span {
    border-radius: 0 0 10px 10px;
    padding: 0 20px;
    font-size: medium;
    background-color: #2196f3;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  #GenderBox {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    padding-left: 10px;
    min-height: 35px;
    z-index: 1;
  }

  #iconBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    top: -50px;
  }

  .mobileAndTab-hide-1 {
    display: block;
  }

  .mobileAndTab-hide-1 .valueTextForDetail {
    padding: 5px 10px !important;
    line-height: 20px !important;
    text-align: justify !important;
  }

  #iconBox span {
    margin-right: 10px;
  }

  .header {
    display: none;
  }

  #filterBar {
    display: none;
  }

  .mainContain {
    max-width: 95vw !important;
  }

  #card-Details {
    display: none;
  }

  #card-Details .property-size {
    display: none;
  }

  #emnities img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  #emnities {
    margin: 5px 0;
  }

  #rentBox {
    font-size: 1rem;
  }

  #IconGroup {
    display: none;
  }

  #card-Heading {
    font-size: 20px !important;
    line-height: 1.3;
    font-weight: normal;
  }

  #card-location {
    font-size: 0.9rem;
    margin-top: 5px;
  }

  .ownerBox {
    display: none;
  }

  .needHelp {
    display: none;
  }

  #card-ButtonBox {
    flex-direction: row;
  }

  #contact-owner {
    font-size: 0.9rem;
    color: #fff !important;
    background-color: #007bff !important;
    border: 1px solid #007bff !important;
  }

  #contact-owner a {
    text-decoration: none;
    color: #fff !important;
  }

  #propertyCard-body {
    padding: 0 20px;
    margin-top: -20px;
  }

  .flatseeker-property-card {
    margin-top: 0px !important;
  }

  .flatseeker-card {
    grid-template-columns: repeat(1, 1fr) !important;
    margin-bottom: 20px !important;
  }

  .flatseeker-card .property-card .propertyImage {
    height: 80px;
    width: 80px;
  }

  .flatseeker-card .property-card .property-image {
    width: 30% !important;
  }

  .flatseeker-card .property-card .propertyNewCard {
    width: 70% !important;
  }

  .property-card .card-down-right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .call-icon,
  .msg-icon,
  .puzzle-icon {
    background-color: rgb(90, 114, 248);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px !important;
    height: 32px !important;
    font-size: 11px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgb(0 0 0 / 0%) 0px 32px 64px;
  }

  .preferences-details ul li {
    font-size: 10px;
    background: black;
    color: white;
    padding: 7px 12px;
    border-radius: 10px;
    text-transform: capitalize;
    list-style-type: none;
    letter-spacing: 1px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #card-Details {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  #mobileviewHeader {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .search-sortBar {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    margin: 0 2vw;
  }

  .mobilview-search-bar {
    width: 80%;
    padding-left: 15px;
  }

  .mobileview-search-icon {
    margin-right: 10px;
    font-size: 15px;
  }

  .mobileview-search-button {
    font-size: 18px;
    padding: 0.7rem 1.2rem;
  }

  .sortButton {
    padding: 0.7rem 1.2rem;
  }

  .header {
    display: none;
  }

  #filterBar {
    display: none;
  }

  .sortButton {
    display: block;
  }

  .mainContain {
    margin-left: 2.5vw !important;
    width: 95vw !important;
  }

  /* #card-Details {
    display: none;
  } */
  #emnities {
    justify-content: flex-start;
    margin: 10px 0;
  }

  #rentBox {
    font-size: 1rem;
  }

  /* #rentBox div {
    text-align: center;
  } */
  #IconGroup {
    display: none;
  }

  #card-Heading {
    font-size: 1rem;
    font-weight: 600;
  }

  #card-location {
    font-size: 0.9rem;
  }

  #card-ButtonBox {
    padding: 0 10px;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0;
  }

  #contact-owner {
    font-size: 0.7rem;
  }

  #send-message {
    font-size: 0.7rem;
    margin-top: 0;
  }
}

#filterBarMobileview div label {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  text-align: left;
}

@media screen and (min-width: 1024px) {
  #propertyCard {
    margin-left: 10px;
  }
}

.propertyNewCard h2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 23px;
  min-height: 50px;
  margin-bottom: 20px;
}

.grid-gap {
  grid-gap: 25px !important;
}

.owner-buttons button {
  margin-top: 0px !important;
}

.owner-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  padding: 25px 0px 0px 0px;
}

.property-new-image {
  position: relative;
}

.flat-seeker-items {
  /* position: absolute; */
  top: 10px;
  z-index: 999;
  /* background: yellow; */
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flat-seeker-items span {
  background: white;
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.homesearch input {
  border: none !important;
}

.homesearch input:focus-visible {
  border: none !important;
}

.flatseeker-card .property-card {
  flex-direction: row;
  position: relative;
}

.flatseeker-card .property-card .property-image {
  /* width: 30%; */
  width: 25%;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  flex-direction: column;
}

.flatseeker-card .property-card #CarouselImage {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #0000005c;
}

.flatseeker-card .property-card .propertyNewCard {
  width: 75%;
  padding: 20px 30px;
}

.flatseekerprefrence li {
  list-style-type: none;
  padding-left: 20px;
}

.flatseekerprefrence {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 12px;
  gap: 5px;
}

.flatseeker-card .property-card .propertyNewCard #card-Details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.flatseeker-owner .new-ownerBox {
  font-weight: bold;
  margin-top: 25%;
}

.flat-seeker-items {
  display: flex !important;
  flex-direction: row;
  margin-top: 15px;
}

.rentout-flatseeker {
  position: absolute;
  top: 0;
  left: 0;
  background: green;
  width: 200px !important;
  color: white;
  border-radius: 0px 30px;
  padding-left: 22px;
}
.navBox a {
  padding: 15px 20px 15px 15px;
  border-radius: 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
}

.dropdown-content a {
  background: black;
  margin: 3px !important;
  padding: 15px;
  border-radius: 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 10px;
  border: 1px solid black;
}

.navBox a img {
  width: 30px !important;
  height: 30px !important;
}
.rightBox-1 a {
  background: #333 !important;
}
.rightBox a {
  /* background: #333; */
  margin: 3px !important;
  padding: 15px;
  border-radius: 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
}
/* .rightBox .dropdown-content a {
  background: #333;
  margin: 3px !important;
  padding: 15px;
  border-radius: 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
} */

.rightBox a img {
  width: 30px !important;
  height: 30px !important;
}

.homesearch input:focus {
  outline: none;
}

.custom-search-bar {
  position: relative;
}

.autocomplete-dropdown-container {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
  background-color: white;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
}

.autocomplete-dropdown-container span {
  padding: 10px;
  display: inline-block;
  width: 100%;

  border-bottom: 1px solid #aca2a26e;
}

.curoselframe iframe body img {
  width: 100%;
  height: 100%;
}

.roundedcurosel #document {
  width: 300px;
  height: 300px;
}

.flatseekerprefrence ul {
  display: flex;
  /* width: 95%; */
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
  align-items: center;
  font-size: 13px;
  text-transform: capitalize;
}

.flatseekerprefrence span {
  margin: 0px;
  padding: 0px;
}

.flatseekerprefrence ul li {
  /* background-color: black; */
  color: gray;
  border-radius: 10px;
  padding: 2px;
}

.propertyNewCard h2 {
  margin-bottom: 0px;
  min-height: 0px;
  font-size: 18px;
  padding-top: 8px;
  font-weight: 600;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.65);
}

#card-location {
  font-size: 15px;
}

.card-upper {
  display: flex;
  /* border-bottom:  2px dotted #adadad; */
  padding: 0px !important;
}

.card-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 12px;
}

.card-down-right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.flat-details span {
  font-size: 12px;
}

.flat-details p {
  margin: 0;
  font-size: 14px;
}

.flatseeker-property-card {
  padding: 0px !important;
  border-bottom: 2px dashed #80808070;
}

.call-icon,
.msg-icon,
.puzzle-icon {
  background-color: rgb(90, 114, 248);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgb(0 0 0 / 0%) 0px 32px 64px;
}

.call-icon,
.msg-icon,
.puzzle-icon {
  color: white;
}

.preferences-details ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px;
  gap: 10px;
  align-items: center;
}

.preferences-details ul li {
  font-size: 12px;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 10px;
  list-style-type: none;
}

/* khush css */
.preferences-details ul li {
  font-size: 10px;
  background: black;
  color: white;
  padding: 3px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  list-style-type: none;
  letter-spacing: 1px;
}

.similar-preferences-details ul li {
  font-size: 10px;
  background: rgb(0, 0, 0);
  color: rgb(239, 239, 239);
  padding: 5px 6px;
  border-radius: 8px;
  text-transform: capitalize;
  list-style-type: none;
  letter-spacing: 1px;
}

#propertyCard {
  border-radius: 16px;
  border: 1px solid #0000001a;
  box-shadow: rgb(40 125 253 / 8%) 0px 7px 29px 0px;
}

.flatseekerprefrence svg {
  color: #5a72f8 !important;
}

.more-properties {
  border-radius: 50%;
  border: 1px solid black;
  background-color: black;
  color: white;
  padding: 5px;
  font-size: 12px;
}

.input-add-box {
  display: flex;
  align-items: center;
}

.input-add-box1 {
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  /* width: 300px; */
  height: 40px;
  background-color: #ffffff; /* Background for the input */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.input-add-box1 input{
  border: none;
  margin: 0;
}
.input-add-box1 .area-add-button{
  margin: 0;
}
.input-add-box1 .area-add-button:hover{
  background-color: black;
}

.suggestion-container {
  margin-top: 50px;
}
.suggestion-containers {
  margin-top: 126px;
  z-index: 10;
}

/* .area-add-button {
  padding: 5px 15px;
  background-color: #287dfd;
  border: none;
  border-radius: 50px;
  color: white;
  height: 40px;
  width: 80px;
} */
.area-add-button {
  background-color: #287dfd;
  border: none;
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
}

.area-add-button svg {
  fill: white; /* Ensure the search icon is white */
  width: 20px;
  height: 20px;
  color: white;
}

.mobile-reset-filter {
  position: absolute;
  width: 160px !important;
  top: 30px;
  right: 40px;
}

.city-location-button {
  background-color: #ECF4FF;
  border: 1px solid #007AFF;
  padding: 8px 14px;
  margin-left: 5px;
  border-radius: 10px;
  font-size: 18px;
  color: #007AFF;
  text-transform: capitalize;
}
.range-bar {
  display: flex;
  gap: 10px;
}

.mobile-filter-modal {
  position: fixed;
  z-index: 9999;
  height: 95%;
  margin-top: 15px;
  margin-bottom: 15px;
}