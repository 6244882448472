.pertiflat-heading {

    justify-content: flex-start !important;
    font-size: 30px !important;
}


.modal-dialog{

    max-width: 80%;
}

/* .valueTextForDetail ul {

    display: flex !important;
    flex-wrap: wrap;
} */

.property-details-box{

    display: flex !important;
}

#propertyPhotos{
  
    width: 100%;
    display: flex;
}

#propertyPhotos{ 

    gap: 8px;
    margin-top: 0px;
}

.flat-details {

    display: grid;
    grid-template-columns: repeat(1,1fr);
}

.left-photo img {

    margin-top: 26px !important;
    object-position: top center;
    object-fit: cover;
}

.modal-content{
 
    background-color: transparent !important;

}


/* #mobile-flat{

    display: none;
}

#laptop-flat{
    display: block;
  }


@media screen and (max-width: 768px) {


  #mobile-flat{

    display: block;
  }
  #laptop-flat{
    display: none;
  }
} */
