.city-selection-modal {
  position: fixed;
  top: 0; /* Start from the top */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  z-index: 8000;
  overflow-y: auto;
  padding: 20px;
}

.city-selection-content {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin-top: 80px; /* Add margin to prevent overlap with the navbar */
  padding: 20px;
  box-sizing: border-box;
}

.city-selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.city-selection-header h2 {
  margin: 0;
  font-size: 24px;
}

.close-modal {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.city-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr);
}

.city-item {
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  padding: 10px;
  border-radius: 8px;
  text-transform: capitalize;
}

.city-item.selected {
  background-color: #e0f7fa; /* Highlight color for selected city */
}

.city-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .city-selection-modal {
    padding: 10px;
  }

  .city-selection-content {
    padding: 15px;
    width: 100%;
    margin-top: 60px; /* Adjust margin for smaller screens */
  }

  .city-selection-header h2 {
    font-size: 20px;
  }

  .city-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .city-item {
    padding: 8px;
  }

  .city-image {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 374px) {
  .city-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .custom-city-dropdown {
    padding: 8px 3px !important;
    font-size: 14px !important;
  }
  /* .area-add-button {
    width: 40px !important;
  } */
  /* .area-add-button svg {
    width: 15px !important;
    height: 15px !important;
    color: white !important; 
    fill: white !important;
  } */
}
