.filter-bar {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  /* background-color: #fff; */
  overflow-x: auto;
}

.filter-bar.no-scroll {
  overflow-x: hidden;
}
.filter-options {
  display: flex;
  gap: 5px;
}

.filter-button .gender-button {
  background-color: white;
  border: 1px solid #e5e8ec;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap; /* Ensure text stays on one line */
  width: 90px; /* Set a fixed width for all buttons */
  justify-content: center; /* Center the text horizontally */
  color: black;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-transform: capitalize;
}
.filter-button .bedroom-button {
  background-color: white;
  border: 1px solid #e5e8ec;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap; /* Ensure text stays on one line */
  width: auto; /* Set a fixed width for all buttons */
  justify-content: center; /* Center the text horizontally */
  color: black;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-transform: capitalize;
}
.filter-button button {
  background-color: white;
  border: 1px solid #e5e8ec;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap; /* Ensure text stays on one line */
  width: auto; /* Set a fixed width for all buttons */
  justify-content: center; /* Center the text horizontally */
  color: black;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-transform: capitalize;
}

.dropdown-arrow {
  font-size: 10px;
  margin-left: 5px;
}

.gender-button {
  position: relative;
}

.gender-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 15px;
  padding: 15px;
  z-index: 50;
  margin-top: 5px;
}

.gender-tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 20%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 1;
}

.gender-tooltip::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 20%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #e0e0e0;
  z-index: 0;
}

.bhk-tooltip {
  position: absolute;

  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 15px;
  padding: 15px;
  z-index: 50;
  margin-left: -52px;
  margin-top: 5px;
}

.bhk-tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 40%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 1;
}

.bhk-tooltip::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 40%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #e0e0e0;
  z-index: 0;
}
.gender-option {
  display: flex;
  flex-direction: column; /* Stack SVG and text vertically */
  align-items: center;
  /* gap: 5px; */
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  transition: background-color 0.2s ease;
  border: 1px solid #abb7c2;
  width: 70px;
}

.gender-option:hover {
  background-color: #f0f0f0;
}

.gender-option svg {
  width: 24px;
  height: 24px;
}

.gender-option span {
  font-size: 14px;
  color: #333;
  text-align: center;
}

.bhk-option {
  display: flex;
  flex-direction: column; /* Stack SVG and text vertically */
  align-items: center;
  /* gap: 5px; */
  cursor: pointer;
  padding: 5px;
  border-radius: 30px;
  transition: background-color 0.2s ease;
  border: 1px solid #abb7c2;
  width: 70px;
  margin: 2px;
}
.occupancy-tooltip .selected,
.bhk-tooltip .selected,
.gender-option.selected,
.occupancy-tooltip .selected,
.furnished-tooltip .selected {
  background-color: white;
  border: 1px solid #3f8efc;
  color: #3f8efc !important;
}

.bhk-option:hover {
  background-color: #f0f0f0;
}

.bhk-option svg {
  width: 24px;
  height: 24px;
}

.bhk-option span {
  font-size: 14px;
  color: #333;
  text-align: center;
}
.bhk-row {
  display: flex;
  margin: 5px;
}
.occupancy-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 15px;
  padding: 7.5px;
  z-index: 50;
  margin-left: -90px;
  margin-top: 5px;
}

.occupancy-tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 40%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 1;
}

.occupancy-tooltip::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 40%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #e0e0e0;
  z-index: 0;
}

.furnished-tooltip {
  position: absolute;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
  padding: 7px;
  z-index: 50;
  margin-left: -335px;
  margin-top: 5px;
  background-color: white;
}

.furnished-tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 95%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 1;
}

.furnished-tooltip::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 95%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #e0e0e0;
  z-index: 0;
}
.furnished-option {
  display: flex;
  flex-direction: column; /* Stack SVG and text vertically */
  align-items: center;
  /* gap: 5px; */
  cursor: pointer;
  padding: 3px;
  border-radius: 30px;
  transition: background-color 0.2s ease;
  border: 1px solid #abb7c2;
  width: 110px;
  /* margin: 2px; */
}

.furnished-option:hover {
  background-color: #f0f0f0;
}

.furnished-option svg {
  width: 24px;
  height: 24px;
}

.furnished-option span {
  font-size: 14px;
  color: #333;
  text-align: center;
}
/* Container for the entire search bar */
.custom-filter-bar {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin-right: 5px;
}

/* City dropdown styling */
.custom-city-dropdown {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px 5px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  /* margin-right: 10px; */
  text-transform: capitalize;
}

.custom-city-dropdown .custom-dropdown-icon {
  margin-left: 8px;
  font-size: 12px;
  color: black;
}

/* Area input styling */
.custom-area-input {
  flex-grow: 1;
  padding: 8px 15px !important;
  border: none !important;
  outline: none;
  border-radius: 25px !important;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333;
  margin: 0px 10px 0px -10px !important;
}

/* Search button styling */
.custom-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0066ff;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.custom-search-button svg {
  color: white;
}

/* SVG button styling */
.custom-svg-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0066ff;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

/* Selected areas container */
.custom-selected-areas {
  display: flex;
  margin-top: 0px;
  width: 100%;
  justify-content: left;
  align-items: center;
  margin-bottom: 5px;
  overflow-x: scroll;
  gap: 10px;
  margin-left: 20px;
}

/* Individual area tag */
.custom-area-tag {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 14px;
  color: #333;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Remove icon inside area tag */
.custom-area-tag .custom-remove-icon {
  margin-left: 8px;
  cursor: pointer;
  color: #ff6666;
  font-weight: bold;
}
.custom-filter-bar-row {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 0 2vw;
}

.mobile-filter {
  display: block;
}

@media screen and (min-width: 1024px) {
  .mobile-filter {
    display: none;
  }
}

/* .city-selection-modal {

  display: block !important;
} */

.added-area-box {
  display: flex;
  align-items: center;
  justify-self: center;
}
